$(document).ready(function ()
{
	//isotope filter
	$grid = $('#filter-sale');
	
	var hash = window.location.hash;

	if(hash){
		console.log(hash);
		$grid.isotope({ filter: '.usedProduct-'+ hash.substr(1) });
	};

		$("#filter-links a").click(function() {
			var selectedItem = $(this).attr('data-filter');
			
			if(selectedItem == 'all'){
				$grid.isotope({ filter: '*' });
			} else{
				$grid.isotope({ filter: '.usedProduct-'+ selectedItem });
			}
		});
	

	



	// Youtube/Vimeo thumbs
	$("[class*='rc-video'] .thumb").click( startVideo );

	function startVideo(e) {
		var block = $(e.currentTarget);
		var iframe = $('iframe', block.parent());
		var src = iframe.attr('src');

		if (src.indexOf('autoplay=0') > -1) {
			iframe.attr('src', src.replace('autoplay=0','autoplay=1') );
		} else {
			iframe.attr('src', src + '?autoplay=1');
		}
		block.fadeOut(150);
	}


	// Lightbox - UNCOMMENT ME BIJ KOPPELEN

	$('a.lightbox').fancybox({
		overlayOpacity: 0.8,
		overlayColor: 'black',
		padding: 0,
		titleShow: true
	});

	// Reservatieformulier
	$('#modalReserveren').on('shown.bs.modal', function (e) {
		$(this).find('form[data-toggle=validator]').validator('destroy');
		$(this).find('form[data-toggle=validator]').validator();
		var afhaling = $(this).find('input[value=Afhaling]');
		var levering = $(this).find('input[value=Levering]');
		var werf = $(this).find('input[id=werf]');

		$(werf).attr("disabled", "disable");

		$(afhaling).change(function(){
			$(werf).attr("disabled", "disable");
		});
		$(levering).change(function(){
			$(werf).removeAttr("disabled");
		});

		$("#wanneernodig").datepicker({
			dateFormat: "dd-mm-yy",
			minDate: new Date(),
		});
	});
});



// CAPTCHA
var captcha_validated = false;

function onSubmitreCAPTCHA(response)
{
	captcha_validated = true;
	$('.rc-form form').submit();
}

function validateForm(event)
{
	event.preventDefault();
	var form = this;

	var validator = $(form).data('bs.validator');
	validator.validate();

	if (captcha_validated && !validator.hasErrors()) {
		form.submit();
	} else if(!captcha_validated) {
		grecaptcha.reset();
		grecaptcha.execute();
	}
}
